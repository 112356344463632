<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-xxl-12 col-sm-12 mx-auto">
        <div class="card" style="margin-top: 5em">
          <div class="row">
            <div class="col-lg-12">
              <div class="card-body">
                <form action="#" method="post">
                  <div
                    class="d-flex p-3 justify-content-between col-lg-4 mx-auto"
                  >
                    <div
                      :style="[
                        {
                          'background-color':
                            firstInfoShow == true ? '#007bff' : '#6c757d',
                        },
                      ]"
                      class="d-flex align-items-center justify-content-center round-circle"
                    >
                      <span class="text-white">1</span>
                    </div>
                    <div
                      :style="[
                        {
                          'background-color':
                            secondInfoShow == true ? '#007bff' : '#6c757d',
                        },
                      ]"
                      class="d-flex align-items-center justify-content-center round-circle"
                    >
                      <span class="text-white">2</span>
                    </div>
                    <div
                      :style="[
                        {
                          'background-color':
                            stepThreeInfo == true ? '#007bff' : '#6c757d',
                        },
                      ]"
                      class="d-flex align-items-center justify-content-center round-circle"
                    >
                      <span class="text-white">3</span>
                    </div>
                    <div
                      :style="[
                        {
                          'background-color':
                            stepFourInfo == true ? '#007bff' : '#6c757d',
                        },
                      ]"
                      class="d-flex align-items-center justify-content-center round-circle"
                    >
                      <span class="text-white">4</span>
                    </div>

                    <div
                      :style="[
                        {
                          'background-color':
                            stepFiveInfo == true ? '#007bff' : '#6c757d',
                        },
                      ]"
                      class="d-flex align-items-center justify-content-center round-circle"
                    >
                      <span class="text-white">5</span>
                    </div>
                  </div>

                  <!-- personal User data -->
                  <div v-show="firstInfoShow">
                    <h1
                      class="text-center mb-5"
                      style="font-size: 18px; font-family: sans-serif"
                    >
                      Dados Pessoais
                    </h1>
                  </div>
                  <div v-show="firstInfoShow">
                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Nome Completo*</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="userData.fullName"
                            @input="onUserTypeFullName"
                          />
                        </div>
                        <p v-if="fullNameError" style="color: red">
                          {{ fullNameError }}
                        </p>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Genero*</label>
                          <select
                            class="form-control"
                            v-model="userData.gender"
                            @change="onUserTypeGender"
                          >
                            <option value="">Genero</option>
                            <option value="Masculino">Masculino</option>
                            <option value="Feminino">Feminino</option>
                          </select>
                        </div>
                        <p v-if="genderError" style="color: red">
                          {{ genderError }}
                        </p>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Data de nascimento*</label>
                          <input
                            type="date"
                            class="form-control"
                            v-model="userData.birthDate"
                            @change="calc_birthdate"
                            @input="calc_birthdate"
                          />
                        </div>
                        <p v-if="birthDateError" style="color: red">
                          {{ birthDateError }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Profissão*</label>
                          <select
                            class="form-control"
                            v-model="userData.profession_id"
                            @change="onUserTypeProfession"
                          >
                            <option value="0">Selecione Profissão</option>
                            <option
                              :value="prof.idProf"
                              v-for="prof in professionData"
                              :key="prof.idProf"
                            >
                              {{ prof.designacaoProf }}
                            </option>
                          </select>
                        </div>
                        <p v-if="professesionError" style="color: red">
                          {{ professesionError }}
                        </p>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Ocupação actual*</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="userData.current_profession"
                            @input="onUserTypeCurrentPosition"
                          />
                        </div>
                        <p v-if="currentPositionError" style="color: red">
                          {{ currentPositionError }}
                        </p>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Formação académica*</label>

                          <select
                            class="form-control form-rounded"
                            v-model="userData.school_academic"
                            @change="onUserTypeTrainingSchool"
                          >
                            <option value="">
                              Selecionar formação académica
                            </option>
                            <option value="Curso Extra-curicular">
                              Curso Extra-curicular
                            </option>
                            <option value="Ensino Secundario incompleto">
                              Ensino Secundario incompleto
                            </option>
                            <option value="Ensino Secundario completo">
                              Ensino Secundario completo
                            </option>
                            <option value="Ensino Tecnico incompleto">
                              Ensino Tecnico incompleto
                            </option>
                            <option value="Ensino Tecnico completo">
                              Ensino Tecnico completo
                            </option>
                            <option value="Ensino Superior incompleto">
                              Ensino Superior incompleto
                            </option>
                            <option value="Ensino Superior completo">
                              Ensino Superior completo
                            </option>
                            <option value="Pós-Graduação incompleto">
                              Pós-Graduação incompleto
                            </option>
                            <option value="Pós-Graduação">Pós-Graduação</option>
                            <option value="Mestrado">Mestrado</option>
                            <option value="Doutorado">Doutorado</option>
                          </select>
                        </div>
                        <p v-if="school_academicnError" style="color: red">
                          {{ school_academicnError }}
                        </p>
                      </div>
                    </div>

                    <div class="form-row d-flex justify-content-end mt-4 mb-2">
                      <button
                        type="button"
                        v-on:click="handleSecondStep"
                        class="btn btn-primary mb-3"
                      >
                        Proximo
                      </button>
                    </div>
                  </div>

                  <div v-show="secondInfoShow">
                    <div>
                      <h1
                        class="text-center mb-5"
                        style="font-size: 18px; font-family: sans-serif"
                      >
                        Residência Actual
                      </h1>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >Selecione o Provincia*</label
                          >
                          <select
                            class="form-control"
                            v-model="userData.province_id"
                            @change="ChangeProvince($event)"
                          >
                            <option value="">Selecione o Provincia</option>
                            <option
                              :value="provinces.id"
                              v-for="provinces in provincesData"
                              :key="provinces.id"
                            >
                              {{ provinces.name }}
                            </option>
                          </select>
                        </div>
                        <p v-if="provinceError" style="color: red">
                          {{ provinceError }}
                        </p>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >Selecione o Distrito*</label
                          >
                          <select
                            :disabled="loadDistricts"
                            class="form-control"
                            @change="getZones"
                            v-model="userData.district_id"
                          >
                            <option value="0">Selecione o Distrito</option>
                            <option
                              :value="district.id"
                              v-for="district in districtData"
                              :key="district.id"
                            >
                              {{ district.name }}
                            </option>
                          </select>
                        </div>
                        <p v-if="districtError" style="color: red">
                          {{ districtError }}
                        </p>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Selecione o cidade*</label>
                          <select
                            :disabled="isMaputoCity"
                            class="form-control"
                            v-model="userData.district_city_id"
                            :style="[
                              {
                                'background-color': isMaputoCity
                                  ? '#6c757d'
                                  : '#2e2f49',
                              },
                            ]"
                          >
                            <option value="0">Selecione o cidade</option>
                            <option
                              :value="district.id"
                              v-for="district in district_cityData"
                              :key="district.id"
                            >
                              {{ district.district_city_name }}
                            </option>
                          </select>
                        </div>
                        <p v-if="cityError" style="color: red">
                          {{ cityError }}
                        </p>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >Selecione o posto administrativo*</label
                          >
                          <select
                            :disabled="isMaputoCity"
                            :style="[
                              {
                                'background-color': isMaputoCity
                                  ? '#6c757d'
                                  : '#2e2f49',
                              },
                            ]"
                            class="form-control"
                            v-model="userData.post_admin"
                          >
                            <option value="0">
                              Selecione o posto administrativo
                            </option>
                            <option
                              :value="district.id"
                              v-for="district in districtData"
                              :key="district.id"
                            >
                              {{ district.name }}
                            </option>
                          </select>
                        </div>
                        <p v-if="post_adminError" style="color: red">
                          {{ post_adminError }}
                        </p>
                      </div>
                      <!-- <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Zona*</label>
                          <select
                            :disabled="loadDistricts"
                            @change="getLocalities"
                            class="form-control"
                            v-model="userData.zone_id"
                          >
                            <option value="0">Selecione o zona</option>
                            <option
                              :value="zone.id"
                              v-for="zone in zoneData"
                              :key="zone.id"
                            >
                              {{ zone.zone_name }}
                            </option>
                          </select>
                        </div>
                      </div> -->

                      <!-- <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Localidade*</label>
                          <select
                            :disabled="loadDistricts"
                            @change="getCircles"
                            class="form-control"
                            v-model="userData.localite_id"
                          >
                            <option value="0">Selecione o localidade</option>
                            <option
                              :value="local.id"
                              v-for="local in localitiesData"
                              :key="local.id"
                            >
                              {{ local.localite_name }}
                            </option>
                          </select>
                        </div>
                      </div> -->

                      <!-- <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Circulo*</label>
                          <select
                            :disabled="loadDistricts"
                            @change="getCells"
                            class="form-control"
                            v-model="userData.circle_id"
                          >
                            <option value="0">Selecione o circulo</option>
                            <option
                              :value="circle.id"
                              v-for="circle in circleData"
                              :key="circle.id"
                            >
                              {{ circle.circle_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Célula*</label>
                          <select
                            :disabled="loadDistricts"
                            class="form-control"
                            v-model="userData.cell_id"
                          >
                            <option value="0">Selecione célula</option>
                            <option
                              :value="cell.id"
                              v-for="cell in cellData"
                              :key="cell.id"
                            >
                              {{ cell.cell_name }}
                            </option>
                          </select>
                        </div>
                      </div> -->
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Bairro*</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="userData.neighborhood"
                            @input="onUserTypeChildHood"
                          />
                        </div>
                        <p v-if="neighborhoodError" style="color: red">
                          {{ neighborhoodError }}
                        </p>
                      </div>
                    </div>

                    <div
                      class="form-row d-flex justify-content-start mt-4 mb-2"
                    >
                      <button
                        type="button"
                        v-on:click="handleBackStepOne"
                        class="btn btn-secondary mb-3"
                      >
                        Voltar
                      </button>

                      <button
                        type="button"
                        v-on:click="handleStepThree"
                        class="btn btn-primary mb-3 ml-2"
                      >
                        Proximo
                      </button>
                    </div>
                  </div>

                  <div v-show="stepThreeInfo">
                    <div>
                      <h1
                        class="text-center mb-5"
                        style="font-size: 18px; font-family: sans-serif"
                      >
                        Contactos
                      </h1>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Email*</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="userData.email"
                            @input="validateEmail"
                          />
                        </div>
                        <p v-if="emailError" style="color: red">
                          {{ emailError }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Contacto(Whatsapp)*</label>
                          <input
                            type="text"
                            class="form-control"
                            @keypress="numberOnly"
                            pattern="/^[0-9]+$/"
                            maxlength="9"
                            v-model="userData.contact"
                            @input="validatePhoneNumber"
                          />
                        </div>
                        <p v-if="contactError" style="color: red">
                          {{ contactError }}
                        </p>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Contacto Alternativo</label>
                          <input
                            type="text"
                            class="form-control"
                            @keypress="numberOnly"
                            pattern="/^[0-9]+$/"
                            maxlength="9"
                            v-model="userData.alternative_contact"
                            @input="validatePhoneNumberAlternative"
                          />
                        </div>
                        <p
                          v-if="phoneNumberErrorAlternative"
                          style="color: red"
                        >
                          {{ phoneNumberErrorAlternative }}
                        </p>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >Classificação Social *</label
                          >
                          <select
                            class="form-control"
                            v-model="userData.social_id"
                          >
                            <option value="0">Selecione classificação</option>
                            <option
                              :value="clas.idCS"
                              v-for="clas in socialClassicationData"
                              :key="clas.idCS"
                            >
                              {{ clas.DescricaoSocial }}
                            </option>
                          </select>
                        </div>
                        <p v-if="classificationError" style="color: red">
                          {{ classificationError }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="form-row d-flex justify-content-start mt-4 mb-2"
                    >
                      <button
                        type="button"
                        v-on:click="handleBackStepTwo"
                        class="btn btn-secondary mb-3"
                      >
                        Voltar
                      </button>

                      <button
                        type="button"
                        v-on:click="handleStepFour"
                        class="btn btn-primary mb-3 ml-2"
                      >
                        Proximo
                      </button>
                    </div>
                  </div>

                  <div v-show="stepFourInfo">
                    <div>
                      <h1
                        class="text-center mb-5"
                        style="font-size: 18px; font-family: sans-serif"
                      >
                        Sonhos
                      </h1>
                    </div>
                    <div class="row mb-2">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >O que gostaria de fazer?</label
                          >
                          <textarea
                            class="form-control"
                            v-model="userData.what_you_wish"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >O que gostaria de ter?</label
                          >
                          <textarea
                            class="form-control"
                            v-model="userData.what_you_want_have"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >O que espera que o governo faca no teu bairro, zona
                            , ou no pais no geral?</label
                          >
                          <textarea
                            class="form-control"
                            v-model="userData.what_you_want_govern_do"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >O que você faria se fizesse parte do governo
                            ?</label
                          >

                          <select
                            v-model="userData.what_you_can_do"
                            class="form-control"
                          >
                            <option
                              value="Área do Interior ou  Administração Interna"
                            >
                              Área do Interior ou Administração Interna
                            </option>
                            <option value="Área dos Negócios Estrangeiros">
                              Área dos Negócios Estrangeiros
                            </option>
                            <option value="Área de Defesa">
                              Área de Defesa
                            </option>
                            <option value="Área das Finanças">
                              Área das Finanças
                            </option>
                            <option value="Área da Educação">
                              Área da Educação
                            </option>
                            <option value="Área da Saúde">Área da Saúde</option>
                            <option value="Área da Justiça">
                              Área da Justiça
                            </option>
                            <option value="Área do Emprego">
                              Área do Emprego
                            </option>
                            <option value="Área do Meio Ambiente">
                              Área do Meio Ambiente
                            </option>
                            <option value="Área da Agricultura">
                              Área da Agricultura
                            </option>
                            <option value="Área do Comércio">
                              Área do Comércio
                            </option>
                            <option value="Área de Energia">
                              Área de Energia
                            </option>
                            <option
                              value="Área da Habitação e Desenvolvimento Urbano"
                            >
                              Área da Habitação e Desenvolvimento Urbano
                            </option>
                            <option value="Área de Cultura e Turismo">
                              Área de Cultura e Turismo
                            </option>
                            <option value="Área da Ciência e Tecnologia">
                              Área da Ciência e Tecnologia
                            </option>
                            <option value="Área Ministério da Juventude">
                              Área Ministério da Juventude
                            </option>
                            <option value="Área do Desporto">
                              Área do Desporto
                            </option>
                            <option value="Área do Género">
                              Área do Género
                            </option>
                            <option value="Área da Administração Pública">
                              Área da Administração Pública
                            </option>
                            <option value="Área dos Transportes">
                              Área dos Transportes
                            </option>
                            <option value="Área das Comunicações">
                              Área das Comunicações
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <textarea
                            class="form-control"
                            v-model="userData.description"
                            placeholder="Deixe sua descrição aqui do que você faria se fizesse parte do governo..."
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-row d-flex justify-content-start mt-4 mb-2"
                    >
                      <button
                        type="button"
                        v-on:click="handleBackStepThree"
                        class="btn btn-secondary mb-3"
                      >
                        Voltar
                      </button>

                      <button
                        type="button"
                        v-on:click="handleStepFive"
                        class="btn btn-primary mb-3 ml-2"
                      >
                        Proximo
                      </button>
                    </div>
                  </div>
                  <div v-show="stepFiveInfo">
                    <div>
                      <h1 class="text-center mb-5">Dados de Acesso</h1>
                    </div>
                    <div class="row mb-2">
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Username*</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="userData.username"
                          />
                        </div>
                        <p v-if="usernameError" style="color: red">
                          {{ usernameError }}
                        </p>
                      </div>

                      <!-- <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Senha*</label>
                          <input
                            type="password"
                            class="form-control"
                            v-model="userData.password"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Confirmar Senha*</label>
                          <input
                            type="password"
                            class="form-control"
                            v-model="userData.confSenha"
                          />
                        </div>
                      </div> -->

                      <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Senha*</label>
                          <div class="input-group-append">
                            <input
                              :type="showPassword ? 'text' : 'password'"
                              class="form-control"
                              v-model="userData.password"
                              @input="validatePassword"
                            />
                            <span class="p-viewer2" @click="showPasswordClick">
                              <i
                                v-if="showPassword == true"
                                class="fa fa-eye"
                                aria-hidden="true"
                              >
                              </i>
                              <i
                                v-else
                                class="fa fa-eye-slash"
                                aria-hidden="true"
                              >
                              </i>
                            </span>
                            <!-- <div class="input-group-tex"> -->
                            <!-- </div> -->
                          </div>
                        </div>
                        <p v-if="passwordError" style="color: red">
                          {{ passwordError }}
                        </p>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Confirmar Senha*</label>
                          <div class="input-group-append">
                            <input
                              :type="showPasswordConf ? 'text' : 'password'"
                              class="form-control"
                              v-model="userData.confSenha"
                            />
                            <span
                              class="p-viewer2"
                              @click="showConfPasswordClick"
                            >
                              <i
                                v-if="showPasswordConf == true"
                                class="fa fa-eye"
                                aria-hidden="true"
                              >
                              </i>
                              <i
                                v-else
                                class="fa fa-eye-slash"
                                aria-hidden="true"
                              >
                              </i>
                            </span>
                          </div>
                        </div>
                        <p v-if="conf_pass_message" style="color: red">
                          {{ conf_pass_message }}
                        </p>
                      </div>
                    </div>

                    <div
                      class="form-row d-flex justify-content-start mt-4 mb-2"
                    >
                      <button
                        type="button"
                        v-on:click="handleBackStepFour"
                        class="btn btn-secondary mb-3"
                      >
                        Voltar
                      </button>

                      <button
                        type="button"
                        v-on:click="handleSubmitButton"
                        class="btn btn-primary mb-3 ml-2"
                      >
                        {{ loading == false ? "Criar" : "Criando..." }}
                      </button>
                    </div>

                    <span
                      >Nota:No <strong class="text-white">username</strong> use
                      um nome discreto, que servira de sua identificao no
                      plataforma. <br />Ex: (laranja, xx_best e etc)
                    </span>
                    <div
                      class="form-row d-flex justify-content-between mt-4 mb-2"
                    >
                      <div class="form-group">
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            v-model="agree"
                            id="basic_checkbox_1"
                          />
                          <label
                            class="custom-control-label"
                            for="basic_checkbox_1"
                            >Concorda com os nossa termos de
                            responsabilidades</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div class="new-account mt-3 text-center">
                  <p>
                    Ja tem uma conta?
                    <router-link style="color: #0093dd" to="/login"
                      >Clique aqui</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="processador" v-if="loading == true">
      <div class="login">
        <img src="assets/images/loader.gif" alt="" /><br />

        <p>Processando...</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import bridge from "../../Api/bridge";
import toastr from "toastr";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      firstInfoShow: true,
      secondInfoShow: false,
      stepThreeInfo: false,
      stepFourInfo: false,
      stepFiveInfo: false,
      personalData: true,
      loadDistricts: true,
      schoolData: false,
      courseData: false,
      documentData: false,
      loading: false,
      deficientTypeDisabled: true,
      userData: {
        fullName: "",
        email: "",
        gender: "",
        birthDate: "",
        profession_id: "",
        social_id: "",
        current_profession: "",
        school_academic: "",
        contact: "",
        alternative_contact: "",
        province_id: "",
        district_id: "",
        district_city_id: "",
        classification: "",
        zone_id: "",
        post_admin: "",
        localite_id: "",
        birthDate: "",
        cell_id: "",
        neighborhood: "",
        password: "",
        confSenha: "",
        permissao: "V",
        username: "",
        what_you_wish: "",
        what_you_want_have: "",
        what_you_want_govern_do: "",
        what_you_can_do: "",
        description: "",
      },
      agree: false,
      showPassword: false,
      showPasswordConf: false,
      isMaputoCity: false,
      provincesData: [],
      districtData: [],
      DataCourse: [],
      zoneData: [],
      circleData: [],
      cellData: [],
      localitiesData: [],
      professionData: [],
      socialClassicationData: [],
      phoneNumberError: null,
      phoneNumberErrorAlternative: null,
      emailError: null,
      disableInputsForOtherProvinces: false,
      district_cityData: [],
      currentPositionError: "",
      school_academicnError: "",
      school_academicnError: "",
      professesionError: "",
      birthDateError: "",
      genderError: "",
      fullNameError: "",
      provinceError: "",
      districtError: "",
      cityError: "",
      neighborhoodError: "",
      post_adminError: "",
      classificationError: "",
      contactError: "",
      usernameError: "",
      passwordError: "",
      passwordValid: "",
      conf_pass_message: "",
    };
  },
  methods: {
    ...mapActions({
      signIn: "auth/login",
    }),

    calc_birthdate(event) {
      const currentYear = new Date().getFullYear();
      const extractYear = new Date(event.target.value);
      const data = extractYear.getFullYear();

      const age = currentYear - data;

      if (age < 18) {
        this.birthDateError = "Desculpe mas não tem idade mínima requisitada";
        return;
      } else {
        this.birthDateError = "";
      }
    },

    onUserTypeProfession(event) {
      if (event.target.value == "0") {
        this.professesionError = "Por favor selecione a profissão";
      } else {
        this.professesionError = "";
      }
    },

    onUserTypeGender(event) {
      if (event.target.value == "") {
        this.genderError = "Por favor selecione o genero";
      } else {
        this.genderError = "";
      }
    },

    onUserTypeCurrentPosition(event) {
      if (event.target.value == "") {
        this.currentPositionError = "O campo ocupacão não pode estar vazio!";
      } else {
        this.currentPositionError = "";
      }
    },

    onUserTypeTrainingSchool(event) {
      if (event.target.value == "") {
        this.school_academicnError =
          "O campo Formação académica não pode estar vazio!";
      } else {
        this.school_academicnError = "";
      }
    },

    onUserTypeFullName(event) {
      if (event.target.value !== "") {
        this.fullNameError = "";
      } else {
        this.fullNameError = "O campo nome não pode esta vazio!";
      }
    },

    onUserTypeChildHood(event) {
      if (event.target.value !== "") {
        this.neighborhoodError = "";
      } else {
        this.neighborhoodError = "O campo bairro não pode esta vazio!";
      }
    },

    showPasswordClick() {
      this.showPassword = !this.showPassword;
    },

    showConfPasswordClick() {
      this.showPasswordConf = !this.showPasswordConf;
    },
    handleSecondStep() {
      if (this.userData.fullName == "") {
        this.fullNameError = "O campo nome não pode estar vazio!";
        return;
      }

      if (this.userData.gender == "") {
        this.genderError = "O campo genero não pode estar vazio!";
        return;
      }

      if (this.userData.birthDate == "") {
        this.birthDateError =
          "O campo data de nascimento não pode estar vazio!";
        return;
      }

      if (this.userData.profession_id == "") {
        this.professesionError = "O campo profissão não pode estar vazio!";
        return;
      }
      if (this.userData.current_profession == "") {
        this.currentPositionError =
          "O campo ocupacão actual não pode estar vazio!";
        return;
      }

      if (this.userData.school_academic == "") {
        this.school_academicnError = "O campo formacão não pode estar vazio!";
        return;
      }

      this.firstInfoShow = false;
      this.secondInfoShow = true;
    },

    handleBackStepOne() {
      this.secondInfoShow = false;
      this.firstInfoShow = true;
    },

    handleBackStepTwo() {
      this.secondInfoShow = true;
      this.stepThreeInfo = false;
    },

    handleStepThree() {
      if (this.userData.province_id == "") {
        this.provinceError = "O campo provincia não pode estar vazio!";
        return;
      }

      if (this.userData.province_id == 1) {
        if (this.userData.district_id == "") {
          this.districtError = "O campo districto não pode estar vazio!";
          return;
        }

        if (this.userData.neighborhood == "") {
          this.neighborhoodError = "O campo bairro não pode estar vazio!";
          return;
        }
      }

      if (this.userData.province_id == 2) {
        if (this.userData.district_id == "") {
          this.districtError = "O campo districto não pode estar vazio!";
          return;
        }

        if (this.userData.district_city_id == "") {
          this.cityError = "O campo profissão não pode estar vazio!";
          return;
        }
        if (this.userData.post_admin == "") {
          this.post_adminError =
            "O campo posto administrativo não pode estar vazio!";
          return;
        }

        if (this.userData.neighborhood == "") {
          this.neighborhoodError = "O campo bairro não pode estar vazio!";
          return;
        }
      }
      if (this.userData.province_id != 2 && this.userData.province_id != 1) {
        if (this.userData.district_id == "") {
          this.districtError = "O campo districto não pode estar vazio!";
          return;
        }

        if (this.userData.neighborhood == "") {
          this.neighborhoodError = "O campo bairro não pode estar vazio!";
          return;
        }
      }

      this.secondInfoShow = false;
      this.stepThreeInfo = true;
    },

    handleStepFour() {
      if (this.userData.email == "") {
        this.emailError = "O campo email não pode estar vazio!";
        return;
      }

      if (this.userData.contact == "") {
        this.contactError = "O campo contacto não pode estar vazio!";
        return;
      }

      if (this.userData.social_id == "") {
        this.classificationError =
          "O campo classificação social não pode estar vazio!";
        return;
      }
      this.stepThreeInfo = false;
      this.stepFourInfo = true;
    },

    handleBackStepThree() {
      this.stepThreeInfo = true;
      this.stepFourInfo = false;
    },

    handleStepFive() {
      this.stepFourInfo = false;
      this.stepFiveInfo = true;
    },

    handleBackStepFour() {
      this.stepFourInfo = true;
      this.stepFiveInfo = false;
    },

    clearInputs() {
      this.userData.alternative_contact = "";
      this.userData.contact = "";
      this.userData.district_id = "";
      this.userData.confSenha = "";
      this.userData.email = "";
      this.userData.password = "";
      this.userData.gender = "";
      this.userData.fullName = "";
      this.userData.province_id = "";
      this.userData.username = "";
    },

    async handleSubmitButton() {
      this.loading = true;

      if (this.userData.username == "") {
        this.loading = false;

        this.usernameError = "O campo username não pode estar vazio!";
        return;
      }

      if (this.userData.password == "") {
        this.loading = false;

        return (this.passwordError = "O campo senha não pode estar vazio!");
      }

      if (this.userData.confSenha == "") {
        this.loading = false;

        return (this.conf_pass_message = "O campo senha não pode estar vazio!");
      }
      if (this.userData.password != this.userData.confSenha) {
        this.loading = false;

        return (this.conf_pass_message = "Confirme a senha!");
      }

      if (this.agree == false) {
        this.loading = false;

        return toastr.warning(
          "Por favor concorda com os nossos termos?",
          "Opsssiiii"
        );
      }

      if (
        this.userData.fullName == "" ||
        this.userData.gender == "" ||
        this.userData.email == "" ||
        this.userData.contact == "" ||
        this.userData.province_id == ""
      ) {
        this.loading = false;
        return toastr.warning(
          "Por favor preecha os campos obrigatorios",
          "Opsssiiii"
        );
      }

      this.couseData = true;
      await axios
        .post(this.url + "Candidate", this.userData)
        .then((response) => {
          console.log(response.data);
          this.loading = false;

          if (response.data.status == false) {
            toastr.error("Por favor verifique o username ou email!!");
          } else {
            localStorage.setItem("user", JSON.stringify(response.data));
            toastr.success("Bem vindo!! acedeu com sucesso");
            this.signIn(response.data);
            this.clearInputs();
            this.goToFirstForm();
          }

          // toastr.success(response.data.message);
        })
        .catch((response) => {
          this.loading = false;

          console.log(response);
        });

      return;
    },

    async getProfessions() {
      await axios
        .get(this.url + "profession")
        .then((response) => {
          console.log(response.data);

          this.professionData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getSocialClassification() {
      await axios
        .get(this.url + "socialClassification")
        .then((response) => {
          // console.log(response);

          this.socialClassicationData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getProvinces() {
      await axios
        .get(this.url + "provinces")
        .then((response) => {
          // console.log(response);

          this.provincesData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async ChangeProvince(evt) {
      this.loadDistricts = true;
      console.log(evt.target.value);
      if (evt.target.value == 1) {
        this.isMaputoCity = true;
      } else if (evt.target.value == 2) {
        this.isMaputoCity = false;
        this.disableInputsForOtherProvinces = true;
      } else {
        this.isMaputoCity = true;
      }
      await axios
        .get(this.url + "district/" + evt.target.value)
        .then((response) => {
          // console.log(response);
          this.districtData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getZones(evt) {
      this.loadDistricts = true;
      console.log(evt.target.value);
      if (evt.target.value == "0") {
        this.districtError = "Selecione o distrito";
      } else {
        this.districtError = "";
      }
      await axios
        .get(this.url + "district_city/" + evt.target.value)
        .then((response) => {
          this.district_cityData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getLocalities(evt) {
      this.loadDistricts = true;
      console.log(evt.target.value);

      await axios
        .get(this.url + "localities/" + evt.target.value)
        .then((response) => {
          // console.log(response);
          this.localitiesData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getCircles(evt) {
      this.loadDistricts = true;
      console.log(evt.target.value);

      await axios
        .get(this.url + "circles/" + evt.target.value)
        .then((response) => {
          // console.log(response);
          this.circleData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getCells(evt) {
      this.loadDistricts = true;
      console.log(evt.target.value);

      await axios
        .get(this.url + "cells/" + evt.target.value)
        .then((response) => {
          // console.log(response);
          this.cellData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    validatePhoneNumber() {
      // Expressão regular para validar o número de telefone
      const phoneNumberRegex = /^(86)\d{8}$/;
      var firstTwoDigits = "";
      if (this.userData.contact.length >= 2) {
        firstTwoDigits = this.userData.contact.slice(0, 2);

        switch (firstTwoDigits) {
          case "84":
            this.phoneNumberError = null;

            break;
          case "85":
            this.phoneNumberError = null;

            break;
          case "86":
            this.phoneNumberError = null;

            break;
          case "87":
            this.phoneNumberError = null;

            break;
          case "82":
            this.phoneNumberError = null;

            break;
          case "83":
            this.phoneNumberError = null;

            break;

          default:
            this.phoneNumberError =
              "Por favor, insira um número de telefone válido.";

            break;
        }
      }
    },

    validatePhoneNumberAlternative() {
      // Expressão regular para validar o número de telefone
      var firstTwoDigits = "";

      if (this.userData.alternative_contact.length >= 2) {
        firstTwoDigits = this.userData.alternative_contact.slice(0, 2);

        switch (firstTwoDigits) {
          case "84":
            this.phoneNumberErrorAlternative = null;

            break;
          case "85":
            this.phoneNumberErrorAlternative = null;

            break;
          case "86":
            this.phoneNumberErrorAlternative = null;

            break;
          case "87":
            this.phoneNumberErrorAlternative = null;

            break;
          case "82":
            this.phoneNumberErrorAlternative = null;

            break;
          case "83":
            this.phoneNumberErrorAlternative = null;

            break;

          default:
            this.phoneNumberErrorAlternative =
              "Por favor, insira um número de telefone válido.";

            break;
        }
      }
    },
    validateEmail() {
      // Expressão regular para validar o formato de um e-mail
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Verifica se o e-mail atende ao formato esperado
      if (!emailRegex.test(this.userData.email)) {
        this.emailError = "Por favor, insira um endereço de e-mail válido.";
      } else {
        this.emailError = "";
      }
    },

    numberOnly($event) {
      let keycode = $event.keyCode ? $event.keyCode : $event.which;

      if ((keycode < 48 || keycode > 57) && keycode !== 46) {
        $event.preventDefault();
      }
    },
  },

  created() {
    this.getProvinces();
    this.getProfessions();
    this.getSocialClassification();
  },
};
</script>

<style scoped>
.bg-image {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  height: 100%;
}

.login {
  position: fixed;
  top: 40%;
  left: 47%;
  padding: auto;
  margin: auto;
}
.login img {
  width: 80px;
  height: 80px;
}

#processador {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;

  width: 100%;

  border-radius: 4px;
  height: 100%;
}

#processador {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  transition: height 0.2s linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

#processador {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: #fff;
}

.round-circle {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.p-viewer,
.p-viewer2 {
  margin-left: -20px;
  margin-top: 10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
</style>
