import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { MainLayout } from "@/layouts";
import MyHome from "@/components/MyHome.vue";
import MyLogin from "@/pages/login/MyLogin.vue";
import MyInscriptions from "@/pages/inscriptions/MyInscriptions.vue";
import MyProfile from "@/pages/profile/MyProfile.vue";
import post from "@/pages/post/post.vue";
import Municipe from "@/pages/Municipes/Municipe.vue";
import check_post from "@/pages/post/check_post.vue";
import Myrecover_password from "@/pages/recover/Myrecover_password.vue";
import donate from "@/pages/donate/donate.vue";
import ListAllUsers from "@/pages/users/ListAllUsers.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: MyLogin,
    meta: {
      middleware: "guest",
      title: `Login`,
    },
  },

  {
    path: "/reset_password/:token?",
    name: "reset_password",
    component: () => import("../pages/login/Reset_password.vue"),
    meta: {
      middleware: "guest",
      title: `New Password`,
    },
  },

  {
    path: "/confirm/:token?",
    name: "confirm",
    component: () => import("../pages/login/Confirmation.vue"),
    meta: {
      middleware: "guest",
      title: `Confirmation`,
    },
  },

  {
    path: "/inscription",
    name: "inscription",
    component: MyInscriptions,
    meta: {
      middleware: "guest",
      title: "Inscription",
    },
  },

  {
    path: "/recover",
    name: "recover",
    component: Myrecover_password,
    meta: {
      middleware: "guest",
      title: "Recover Password",
    },
  },

  {
    path: "/",
    // beforeEnter:(to, from, next)=>{
    //   if (store.state.auth.authenticated == true) {
    //     next("/dashboard");
    //     // console.log(to);
    //    }
    // },
    component: MainLayout,
    meta: {
      middleware: "auth",
      title: "Home",
    },
    children: [
      {
        path: "/",
        component: MyHome,
        meta: {
          title: "Home",
        },
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: MyHome,
        meta: {
          title: "Home",
        },
      },
      {
        path: "/profile/:id",
        name: "profile",
        component: MyProfile,
        meta: {
          title: "Profile",
        },
      },

      {
        path: "add-municipe",
        name: "municipe",
        component: Municipe,
        meta: {
          title: "Municipes",
        },
      },
      {
        path: "/check_post/:id",
        name: "check_post",
        component: check_post,
      },
      {
        path: "/donate",
        name: "Donate",
        component: donate,
      },

      {
        path: "/all-users",
        name: "users",
        component: ListAllUsers,
      },
    ],
  },
  {
    path: "/post/:id",
    component: () => import("@/layouts/otherLayout"),
    meta: {
      title: "Comments",
    },
    children: [
      {
        path: "/post/:id",
        name: "comments",

        component: post,
      },
    ],
  },

  {
    path: "/notifications",
    // component: () => import("@/layouts/otherLayout"),
    component: () => import("@/pages/notifications/notifications"),
    meta: {
      title: "Notifications",
    },
    // children: [
    //   {
    //     path: "/notifications",
    //     name: "notifications",
    //   },
    // ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.middleware == "guest") {
    if (store.state.auth.authenticated) {
      next("/");
      console.log("logsss");
    }

    next();
  } else {
    if (store.state.auth.authenticated) {
      // if (store.state.auth.user.page_type == "client") {

      // }

      // router.replace("/dashboard")
      next();
    } else {
      next({ name: "login" });
    }
  }
});
export default router;
